<template>
	<div>
		<el-dialog title="标注异常" @close="cancel()" :visible.sync="showDialog" width="35%" center >
			<div class="opts_wrap" v-loading="loading">
				<div class="opts_line">
					<p class="txt">
						<span class="label">发货需求：</span>
						<span class="val">
							<el-radio-group v-model="is_abnormal">
							    <el-radio :label="1">需要仓库发货</el-radio>
							    <el-radio :label="2">不需要仓库发货</el-radio>
							  </el-radio-group>
						</span>
					</p>
				</div>
				<div class="opts_line">
					<p class="txt">
						<span class="label">备注信息：</span>
						<span class="val">
							<el-input
								class="textarea"
							  type="textarea"
							  :rows="5"
							  placeholder="请输入订单异常备注信息,此备注信息不对用户展示"
							  v-model="abnormal_words">
							</el-input>

						</span>
					</p>
				</div>
				<div class="btn_wrap">
					<p class="btn" @click="saveBtn()">修改</p>
					<p class="btn" @click="cancel()">关闭</p>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "orderAbnormal",
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: false
		},
		order_ids: {
			type: Array,
			default: []
		},
	},
	data() {
		return {
			showDialog:false,
			is_abnormal:1,
			abnormal_words:'',
			loading: false
		};
	},
	
	created(){
	},
	mounted() {
	},
	
	computed:{
		...mapState({
			
		}),
	},
	watch:{
		show(val) {
			console.log(val)
			if(val){
				this.info = {}
				this.init()
			}
		}
	},
	
	methods: {
		...mapActions({
			opOrderManageBatAbnormal:"ucenter/opOrderManageBatAbnormal",
		}),
		init(){
			this.abnormal_words = ''
			this.is_abnormal = 1
			this.showDialog = true
			
		},
		saveBtn(){
			if(this.loading) return
			this.loading = true
			let param = {ids:this.order_ids,state:this.is_abnormal,abnormal_words:this.abnormal_words}
			this.opOrderManageBatAbnormal({data:param,success:(res) => {
				this.loading = false
				if(res.code == 200){
					this.$message({message: res.msg,type: 'success'})
					this.$emit('complete', {})
					this.cancel()
				}else{
					this.$message({message: res.msg,type: 'error'})
				}
			}})
		},
		copyString(str){
			var input = document.createElement("input"); // 创建input对象
			input.value = str; // 设置复制内容
			document.body.appendChild(input); // 添加临时实例
			input.select(); // 选择实例内容
			document.execCommand("Copy"); // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$message({
				message: '复制成功',
				type: 'success'
			});
		},
		
		cancel(){
			console.log('ccc')
			this.$emit('close', {})
			this.showDialog = false
		}
	},

}
</script>

<style scoped>
	.opts_wrap{padding-top: 14px;}
	.opts_wrap .opts_line{margin-top: 20px;display: flex;align-items: center;}
	.opts_line .txt{width: 100%;font-size: 16px;display: flex;line-height: 24px;}
	.opts_line .txt .star{font-size: 16px;}
	.opts_line .txt .val{flex: 1;}
	.opts_line .txt .val .textarea{width: 100%;}
	.opts_line .txt .label{min-width: 80px;display: inline-block;}
	.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
	.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
	.w80{width: 80px;}
	.el-icon-document-copy{cursor: pointer;}
	.el-input--suffix .el-input__inner{padding: 0 0 0 12px !important;font-size: 16px;}
</style>
<style>

	/*red border color*/
  .el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover,.el-textarea__inner:focus,.el-textarea__inner:hover,.el-textarea__inner:checked{border-color: var(--red) !important;}
	
	/*red color*/
	.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
</style>